@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-ExtraBold.woff2") format("woff2"),
    url("Gilroy-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

$body-font-family: "Space Mono", -apple-system, BlinkMacSystemFont, "Segoe UI",
  "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
  "Helvetica Neue", sans-serif;

body {
  font-family: $body-font-family;
  font-weight: 600;
  color: #303030;
  // background-color: #282A3A;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // Scrollbars
  // REF: https://www.digitalocean.com/community/tutorials/css-scrollbars
  /* Works on Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: #9f9f9f transparent;
  }

  /* Works on Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 6px;
  }

  *::-webkit-scrollbar-track {
    background: transparent;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #9f9f9f;
    border-radius: 3px;
    border: none;
  }
}

h1,
h2,
h3,
h4 {
  font-family: Gilroy, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 800;
  color: #373737;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 16px;
  margin-bottom: 16px;
}

button,
textarea {
  font-family: $body-font-family !important;
  font-weight: 600 !important;
}

.easy-edit-hover-on {
  font-style: unset !important;
}

$cell-border-color: hsl(205, 5%, 62%);
$cell-border: 1px solid $cell-border-color;

.column-title-container {
  text-align: center;
  background-color: #eaf2f1;
  border-bottom: $cell-border;
  border-right: $cell-border;
  padding-left: 16px;
  padding-right: 16px;
  position: sticky;
  top: 0;
  z-index: 1;

  &.second-row {
    top: 97px;
    text-transform: lowercase;
  }

  .column-title {
    text-transform: lowercase;
  }
}

.row-title-container {
  text-align: right;
  border-bottom: $cell-border;
  border-right: $cell-border;
  padding-left: 16px;
  padding-right: 8px;

  .row-title {
    text-transform: lowercase;
  }
}

.cell-container {
  max-height: 70vh;
  background-color: #f9f9ff;
  border-bottom: $cell-border;
  border-right: $cell-border;
  overflow-y: auto;
}

.droppable-container {
  font-size: 14px;

  .work-item {
    border: 2px solid hsl(0, 0%, 25%);
    border-radius: 6px;
    box-shadow: 0px 2.5px 0px rgba(0, 0, 0, 0.2);

    &.expedite {
      background-color: rgba(253, 159, 193, 1);
    }

    &.standard {
      background-color: #ffd700;
    }

    &.fixed-delivery-date {
      background-color: #9aedff;
    }

    &.intangible {
      background-color: rgba(178, 235, 84, 1);
    }

    .easy-edit-wrapper {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  textarea {
    outline: none;
    resize: vertical;
  }
}

.options-panel-button {
  margin: 8px;
}
